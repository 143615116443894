<script>
import NewPositionCard from '@/components/positions/NewPositionCard'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import EmptyList from '@/components/general/EmptyList.vue'
import Loading from '@/components/general/Loading'
export default {
  name: 'SavedCandidatures',
  components: {
    NewPositionCard,
    Breadcrumbs,
    EmptyList,
    Loading
  },
  data () {
    return {
      favorites: [],
      isLoading: true
    }
  },
  created () {
    this.$store.dispatch('attemptGetUserFavoritePosition', { aggregator: this.getCompanyDomainAndWorkspace() }).then((data) => {
      this.favorites = data.map(position => ({
        id: position.positionId,
        title: position.positionTitle,
        company: {
          name: position.companyName,
          logo: position.logo,
          image: position.image
        },
        salary: position.salary,
        salaryPeriod: position.salaryPeriod,
        salaryType: position.salaryType,
        positionUrl: position.positionUrl,
        location: position.locationName,
        telecommute: position.telecommute,
        isFavorite: true
      }))
      this.isLoading = false
    })
  },
  computed: {
    breadcrumbsPaths () {
      const isBusiness = this.$route.path.includes('/c/')
      const path = [
        {
          text: isBusiness ? 'internal.mobility:title' : 'main.header:candidacy',
          href: isBusiness ? this.$router.resolve({ name: 'business.internal.positions', params: { companyDomain: this.selectedWorkspace.subdomain } }).href : this.$router.resolve({ name: this.selectedWorkspace.type + '.candidature.index' }).href
        },
        {
          text: 'candidature.list:saved.positions.title',
          href: this.$route.path
        }
      ]
      return path
    }
  }
}
</script>
<template>
  <div>
    <Loading class="loading-candidature--wrapper" v-if="isLoading" />
    <div v-else class="view-all-template--container">
      <div class="view-all-template--content">
        <breadcrumbs :items="breadcrumbsPaths" v-if="false" />
        <h2 v-if="false">{{ $t('candidature.list:saved.positions.title') }}</h2>
        <p>{{ $t('candidature.list:not.finished.subtitle') }}</p>
      </div>
      <div class="view-all--items">

        <div class="flex-saved" v-if="favorites.length && !isLoading">
          <new-position-card v-for="position in favorites" :key="position.id" :position="position" />
        </div>
        <empty-list v-else-if="!favorites.length && !isLoading" :title="$t('empty.state.title:empty.content')"
          :image="'/assets/images/empty-box.png'"></empty-list>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.flex-saved {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  .position-card--container {
    margin: 8px 8px 40px 8px;
  }
}
</style>
